/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

const addNewPropsToPropType = (members) => ({
    ...members,
    clickOutside: PropTypes.bool,
    isClosableOnKeydown: PropTypes.bool
});

const addIsClosableToPropType = (members) => ({
    ...members,
    isClosableOnKeydown: PropTypes.bool.isRequired
});

const addNewPropsToDefault = (members) => ({
    ...members,
    clickOutside: true,
    isClosableOnKeydown: true
});

const addNewPropsToContainerProps = (args, callback, instance) => {
    const { clickOutside, isClosableOnKeydown } = instance.props;

    return {
        ...callback(args),
        clickOutside,
        isClosableOnKeydown
    };
};

const beforeEvenListener = (args, callback, instance) => {
    const { isClosableOnKeydown } = instance.props;

    if (!isClosableOnKeydown) {
        return;
    }

    callback(...args);
};

export default {
    'Component/Popup/Container': {
        'member-property': {
            propTypes: addNewPropsToPropType,
            defaultProps: addNewPropsToDefault
        },
        'member-function': {
            containerProps: addNewPropsToContainerProps
        }
    },
    'Component/Popup/Component': {
        'member-property': {
            propTypes: addIsClosableToPropType
        },
        'member-function': {
            componentDidMount: beforeEvenListener,
            componentWillUnmount: beforeEvenListener
        }
    }
};
