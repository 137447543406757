/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { GDPR_BROWSER_DATABASE_LOCATION } from '../config/GDPR.config';

const addToOnSignInSuccess = (args, callback) => {
    callback(...args);
    BrowserDatabase.deleteItem(GDPR_BROWSER_DATABASE_LOCATION);
};

export default {
    'Component/MyAccountSignIn/Container': {
        'member-function': {
            onSignInSuccess: addToOnSignInSuccess
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onSuccess: addToOnSignInSuccess
        }
    }
};
